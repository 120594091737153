import queryString from 'query-string';
import { generatePath } from 'react-router';

const PROCESS_ORDER = 'process-order';
const CARRIERS_ACCOUNTS = 'carriers-accounts';
const MATERIALS = 'materials';
const CUSTOMER = 'customer';
const USERS = 'users';
const ORDER = 'order';
const BATCHES = 'batches';
const SETTINGS = 'settings';
const MANAGE_PALLETS = 'manage-pallets';
const UNLOAD_APPOINTMENT = 'unload-appointments';
const VERIFY_APPOINTMENT = 'verify-appointments';
export const TRUCKING_JOBS = 'trucking-jobs';
export const DRAYAGE_JOBS = 'drayage-jobs';
const FULFILLED_SERVICES = 'fulfilled-services';
const WAREHOUSES = 'warehouses';
const PURCHASED_LABEL = 'purchased-labels';
const RECEIPTS = 'receipts';
const APPOINTMENTS = 'appointments';
const APPOINTMENT_CHECK_IN = 'appointment-check-in';
const STOCK = 'stock';
const LOCATIONS = 'locations';

export const pathParams = {
  ORDER_ID: 'ORDER_ID',
  BATCH_ID: 'BATCH_ID',
  CARRIER_ID: 'CARRIER_ID',
  NESTED_PAGE: 'NESTED_PAGE',
  SUB_NESTED_PAGE: 'SUB_NESTED_PAGE',
  PRODUCT_ID: 'PRODUCT_ID',
  VENDOR_ID: 'VENDOR_ID',
  CUSTOMER_ID: 'CUSTOMER_ID',
  USER_ID: 'USER_ID',
  LOAD_ID: 'LOAD_ID',
  TRUCKING_JOB_ID: 'TRUCKING_JOB_ID',
  TRUCKING_JOB_TAB: 'TRUCKING_JOB_TAB',
  DRAYAGE_JOB_ID: 'DRAYAGE_JOB_ID',
  DRAYAGE_JOB_TAB: 'DRAYAGE_JOB_TAB',
  TRUCKING_LOAD_TAB: 'TRUCKING_LOAD_TAB',
  DRAYAGE_DELIVERY_ORDER_ID: 'DRAYAGE_DELIVERY_ORDER_ID',
  INVOICE_ID: 'INVOICE_ID',
  BILL_ID: 'BILL_ID',
  SHIPMENT_ID: 'SHIPMENT_ID',
  REPORT_TYPE: 'REPORT_TYPE',
  FULFILLED_SERVICE_ID: 'FULFILLED_SERVICE_ID',
  WAREHOUSE_ID: 'WAREHOUSE_ID',
  ORDER_FULFILLMENT_DASHBOARD_TAB: 'ORDER_FULFILLMENT_DASHBOARD_TAB',
  SHIPPING_DASHBOARD_TAB: 'SHIPPING_DASHBOARD_TAB',
  RECEIPT_ID: 'RECEIPT_ID',
  CUSTOMER_ITEM_ID: 'CUSTOMER_ITEM_ID',
  APPOINTMENT_ID: 'APPOINTMENT_ID',
  CHECK_IN_ID: 'CHECK_IN_ID',
  COMPANY_ID: 'COMPANY_ID',
  VIEW_TYPE: 'VIEW_TYPE',
  ITEM_ID: 'ITEM_ID',
  LOCATION_ID: 'LOCATION_ID',
};

export const nestedPages = {
  VENDORS: 'vendors',
  PRODUCTS: 'products',
  MATERIAL_ORDERS: 'material-orders',
  MATERIAL_INVENTORY: 'material-inventory',
  MATERIAL_REORDER: 'material-reorder',
  CUSTOMER_ORDERS: 'customer-orders',
  CUSTOMER_SHIPPING_HISTORY: 'customer-shipping-history',
  CUSTOMER_ACCOUNTING: 'customer-accounting',
  CUSTOMER_ACCOUNTING_INVOICES: 'customer-invoices',
  CUSTOMER_DOCUMENTS: 'customer-documents',
  CUSTOMER_CONTRACT: 'customer-contract',
  CUSTOMER_ITEMS: 'customer-items',
  CUSTOMER_USERS: 'customer-users',
  CUSTOMER_RESELLER_BILLS: 'customer-reseller-bills',
  CUSTOMER_SERVICES: 'customer-services',
  USERS_CUSTOMERS: 'customers',
  USERS_INTERNAL: 'internal',
  ORDER_ITEMS: 'order-items',
  ORDER_PACKING_SHIPMENTS: 'order-packing-shipments',
  ORDER_BILLING: 'order-billing',
  ORDER_NOTES: 'order-notes',
  ORDER_DOCUMENTS: 'order-documents',
  ORDER_PHOTOS: 'order-photos',
  ORDER_ACTIVITY: 'order-activity',
  ORDER_RETURNS: 'order-returns',
  ORDER_RESHIP: 'order-reships',
  SETTINGS_WORKSTATIONS: 'workstation-settings',
  SETTINGS_BATCH_TEMPLATES: 'batch-templates-settings',
  SETTINGS_CC_PROCESSING_FEES: 'credit-card-processing-fees-settings',
  SETTINGS_QB_VENDORS: 'qb-vendors-settings',
  SP_ESTIMATE: 'sp-estimate',
  BATCH_ORDERS: 'orders',
  BATCH_ERRORS: 'errors',
  STORE_PALLETS: 'store-pallets',
  PULL_PALLETS: 'pull-pallets',
  DISPATCH_PALLETS: 'dispatch-pallets',
  TRUCKING_JOBS_OPENED: 'open',
  TRUCKING_JOBS_SCHEDULED: 'scheduled',
  TRUCKING_JOB_DETAILS: 'trucking-job-details',
  TRUCKING_JOB_BILLING: 'trucking-job-billing',
  TRUCKING_JOB_DOCUMENTS: 'trucking-job-documents',
  TRUCKING_JOB_PHOTOS: 'trucking-job-photos',
  TRUCKING_JOB_PACKING: 'trucking-job-packages',
  TRUCKING_JOB_ACTIVITY: 'trucking-job-activity',
  EDIT_TRUCKING_JOB: 'edit-quote',
  DRAYAGE_JOBS_OPENED: 'open',
  DRAYAGE_JOBS_COMPLETED: 'completed',
  DRAYAGE_JOB_DETAILS: 'drayage-job-details',
  DRAYAGE_JOB_DOCUMENTS: 'drayage-job-documents',
  DRAYAGE_JOB_PHOTOS: 'drayage-job-photos',
  DRAYAGE_JOB_BILLING: 'drayage-job-billing',
  DRAYAGE_JOB_ACTIVITY: 'drayage-job-activity',
  MATERIAL_REPORTS: 'material',
  P_L_REPORTS: 'profit-and-loss',
  EMPLOYEE_ACTIVITY: 'employee-activity',
  SERVICES_REPORTS: 'services',
  RESELLER_REPORT: 'resellers',
  DRAYAGE_REPORT: 'drayage',
  ORDER_FULFILLMENT_REPORT: 'order-fulfillment',
  CUSTOMERS_REPORT: 'customers',
  FULFILLED_SERVICE_OVERVIEW: 'overview',
  FULFILLED_SERVICE_DOCUMENT: 'document',
  FULFILLED_SERVICE_PHOTO: 'photo',
  FULFILLED_SERVICE_ACTIVITY: 'activity',
  PURCHASED_LABEL_OVERVIEW: 'overview',
  PURCHASED_LABEL_BILLING: 'billing',
  FULFILLMENT_DASHBOARD_SP: 'small-parcel',
  FULFILLMENT_DASHBOARD_FREIGHT: 'freight',
  RECEIPT_TRANSPORTATION: 'transportation',
  RECEIPT_ITEMS: 'items',
  RECEIPT_PACKAGES: 'packing',
  RECEIPT_NOTES: 'notes',
  RECEIPT_DOCUMENTS: 'documents',
  RECEIPT_ACTIVITY: 'activity',
  RECEIPT_BILLING: 'billing',
  RECEIPT_PHOTOS: 'photos',
  BILL_DETAILS: 'bill-details',
  SHIPMENT_FEES: 'shipment_fees',
  APPOINTMENT_OVERVIEW: 'overview',
  APPOINTMENT_DOCUMENTS: 'documents',
  APPOINTMENT_PHOTOS: 'photos',
  APPOINTMENT_ACTIVITY: 'activity',
  APPOINTMENT_BILLING: 'billing',
  EDIT_APPOINTMENT: 'edit-appointment',
  WAREHOUSE_SETTINGS: 'warehouse-settings',
  WAREHOUSE_RESOURCES: 'warehouse-resources',
  WAREHOUSE_LOCATIONS: 'warehouse-locations',
  SHIPPING_PROFIT_AND_LOSS: 'profit-and-loss',
  SHIPPING_SMALL_PARCEL_METRIC: 'small-parcel-metric',
};

export const subNestedPages = {
  WAREHOUSE_ASSETS: 'assets',
  WAREHOUSE_WORKSTATIONS: 'workstations',
  WAREHOUSE_DOCKS: 'docks',
  WAREHOUSE_STORAGE_LOCATIONS: 'storage-locations',
  WAREHOUSE_STAGING_LOCATIONS: 'staging-locations',
  WAREHOUSE_QUARANTINE_LOCATIONS: 'quarantine-locations',
};

export const reportTypes = {
  MATERIAL_OVERVIEW: 'material-overview',
  MATERIAL_DETAILS_REPORT_LIST: 'material-details-list',
  MATERIAL_REPORT_LIST: 'material-list',
  CUSTOMERS_MATERIAL_REPORT: 'material-customers-list',
  MATERIAL_PER_CUSTOMER_REPORT: 'material-per-customers-list',
  MATERIAL_PER_CATEGORY_REPORT: 'material-per-category-list',
  MATERIAL_VENDOR_REPORT: 'material-vendor-list',
  P_L_FEE_CATEGORY: 'fee-category',
  P_L_FEE_CATEGORY_TYPE: 'fee-category-type',
  P_L_FULFILLED_SERVICE: 'fulfilled-services',
  P_L_FEE_TYPE: 'fee-type-customer',
  P_L_FEE_TYPE_WAREHOUSE: 'fee-type-customer-warehouse',
  P_L_FEE: 'fees',
  EMPLOYEE_ACTIVITY: 'employee',
  EMPLOYEE_ACTIVITY_TYPE: 'activity-type',
  ORDER_FULFILLMENT_EMPLOYEE_ACTIVITY_TYPE: 'order-activity-type',
  ORDER_DISPATCHING_EMPLOYEE_ACTIVITY_TYPE: 'order-dispatching-type',
  SPECIAL_PROJECTS_EMPLOYEE_ACTIVITY_TYPE: 'special-projects-type',
  SERVICES_ORDER_FULFILLMENT: 'order-fulfillment',
  SERVICES_TRUCKING_JOBS: 'trucking-jobs',
  SERVICES_DRAYAGE_JOBS: 'drayage-jobs',
  SERVICES_RESELLER_JOBS: 'reseller-jobs',
  SERVICES_PURCHASE_LABELS: 'purchase-labels',
  SERVICES_FULFILLED: 'fulfilled',
  ORDER_FULFILLMENT_LATE_ORDERS: 'late-orders',
  ORDER_FULFILLMENT_CLOSED_SAME_DAY: 'closed-same-day',
  ORDER_FULFILLMENT_PHYSICAL_ITEMS: 'physical-items',
  ORDER_FULFILLMENT_ITEMS: 'items',
  DRAYAGE_JOB_STATUS_CHANGES: 'status-changes',
};

export const stockViewTypes = {
  DETAILS: 'details',
  QUICK: 'quick',
};

export const paths = {
  LOGIN: 'login',
  FORGOT_PASSWORD: 'forgot-password',
  MF_AUTH: 'mf-auth',
  RESET_PASSWORD: 'reset-password',
  MOBILE_HOME: 'mobile/home',
  MOBILE_PHOTOS: 'mobile/photos',
  CREATE_COMPANY_USER_ACCOUNT: 'create-account',
  COMPANY_USER_PROFILE: `company/:${pathParams.COMPANY_ID}/user/:${pathParams.USER_ID}/profile`,
  ORDER_FULFILLMENT_DASHBOARD: `dashboards/order-fulfillment/:${pathParams.ORDER_FULFILLMENT_DASHBOARD_TAB}?`,
  P_L_DASHBOARD: 'dashboards/profit-and-loss',
  MAIN_KPI_DASHBOARD: 'dashboards/main-kpi',
  SHIPPING_DASHBOARD: `dashboards/shipping/:${pathParams.SHIPPING_DASHBOARD_TAB}?`,
  ADMIN_REPORTS: `reports/:${pathParams.REPORT_TYPE}/:${pathParams.NESTED_PAGE}?`,
  SHARED_REPORTS: `shared-reports/:${pathParams.REPORT_TYPE}/:${pathParams.NESTED_PAGE}?`,
  ACCOUNTING: 'accounting',
  RESELLER_BILLS: 'reseller-bills',
  DOCUMENTS: 'documents',
  ORDERS: 'orders',
  ORDER_PAGES: `${ORDER}/:${pathParams.ORDER_ID}/:${pathParams.NESTED_PAGE}`,
  PROCESS_RETURN: `${ORDER}/:${pathParams.ORDER_ID}/returns/process-return`,
  BATCH_ORDERS: 'batch-orders',
  BATCH_LIST: BATCHES,
  BATCH_PAGES: `${BATCHES}/:${pathParams.BATCH_ID}/:${pathParams.NESTED_PAGE}`,
  SHIPPING_HISTORY: 'shipping-history',
  PRINT_LABEL: 'print-label',
  ADD_NEW_TRUCKING_JOB: `trucking-jobs/add`,
  TRUCKING_JOBS: `${TRUCKING_JOBS}/:${pathParams.TRUCKING_JOB_TAB}`,
  EDIT_TRUCKING_JOB: `${TRUCKING_JOBS}/:${pathParams.TRUCKING_JOB_TAB}/:${pathParams.TRUCKING_JOB_ID}/edit-quote`,
  TRUCKING_JOB_RATES: `${TRUCKING_JOBS}/:${pathParams.TRUCKING_JOB_TAB}/:${pathParams.TRUCKING_JOB_ID}/rates`,
  TRUCKING_JOB_PAGES: `${TRUCKING_JOBS}/:${pathParams.TRUCKING_JOB_TAB}/:${pathParams.TRUCKING_JOB_ID}/:${pathParams.NESTED_PAGE}`,
  CUSTOMER_VIEW_QUOTE: `${TRUCKING_JOBS}/customer-view/:${pathParams.TRUCKING_JOB_ID}`,
  ADD_NEW_DRAYAGE_JOB: `${DRAYAGE_JOBS}/add`,
  DRAYAGE_JOBS: `${DRAYAGE_JOBS}/list/:${pathParams.DRAYAGE_JOB_TAB}`,
  EDIT_DRAYAGE_DELIVERY_ORDER_JOBS: `${DRAYAGE_JOBS}/:${pathParams.DRAYAGE_JOB_TAB}/:${pathParams.DRAYAGE_DELIVERY_ORDER_ID}/edit/delivery-order-jobs`,
  CARRIER_CONFIRM_DRAYAGE_DELIVERY_ORDER_JOB: `${DRAYAGE_JOBS}/:${pathParams.DRAYAGE_DELIVERY_ORDER_ID}/confirm-view`,
  DRAYAGE_JOB_PAGES: `${DRAYAGE_JOBS}/:${pathParams.DRAYAGE_JOB_TAB}/:${pathParams.DRAYAGE_JOB_ID}/:${pathParams.NESTED_PAGE}`,
  GET_SP_ESTIMATE: `small-parcel-estimate/:${pathParams.NESTED_PAGE}`,
  PURCHASED_LABEL,
  PURCHASED_LABEL_DETAILS: `${PURCHASED_LABEL}/details/:${pathParams.FULFILLED_SERVICE_ID}/:${pathParams.NESTED_PAGE}`,
  PURCHASE_LABEL: `${PURCHASED_LABEL}/purchase`,
  CUSTOMERS: 'customers',
  CUSTOMER_PAGES: `${CUSTOMER}/:${pathParams.CUSTOMER_ID}/:${pathParams.NESTED_PAGE}`,
  EDIT_CUSTOMER_ITEM: `${CUSTOMER}/:${pathParams.CUSTOMER_ID}/edit-item/:${pathParams.CUSTOMER_ITEM_ID}`,
  CUSTOMER_AUTHORIZE_AMAZON_LANDING_PAGE: `${CUSTOMER}/authorize-amazon-redirect`,
  CUSTOMER_INVOICE_DETAILS: `${CUSTOMER}/:${pathParams.CUSTOMER_ID}/:${pathParams.NESTED_PAGE}/${nestedPages.CUSTOMER_ACCOUNTING_INVOICES}/:${pathParams.INVOICE_ID}`,
  EDIT_CUSTOMER_PROFILE: `${CUSTOMER}/:${pathParams.CUSTOMER_ID}/edit`,
  BILLS: 'bill',
  ACCOUNT_RECEIVABLE: `account-receivable`,
  ACCOUNT_PAYABLE: `account-payable`,
  ACCOUNT_PAYABLE_DETAILS_PAGE: `account-payable/:${pathParams.SHIPMENT_ID}`,
  BILL_DETAILS_PAGE: `bill/:${pathParams.BILL_ID}/:${pathParams.NESTED_PAGE}`,
  CARRIERS_ACCOUNTS,
  EDIT_CARRIER_ACCOUNT: `${CARRIERS_ACCOUNTS}/edit/:${pathParams.CARRIER_ID}`,
  ADD_CARRIER_ACCOUNT: `${CARRIERS_ACCOUNTS}/add`,
  MANAGE_CARRIER_ACCOUNT_SERVICES: `${CARRIERS_ACCOUNTS}/services`,
  MATERIALS: `${MATERIALS}/:${pathParams.NESTED_PAGE}`,
  ADD_PRODUCTS: `${MATERIALS}/${nestedPages.PRODUCTS}/add`,
  EDIT_PRODUCTS: `${MATERIALS}/${nestedPages.PRODUCTS}/edit/:${pathParams.PRODUCT_ID}`,
  ADD_VENDORS: `${MATERIALS}/${nestedPages.VENDORS}/add`,
  EDIT_VENDORS: `${MATERIALS}/${nestedPages.VENDORS}/edit/:${pathParams.VENDOR_ID}`,
  ADD_USERS: `${USERS}/add`,
  USERS: `${USERS}/:${pathParams.NESTED_PAGE}`,
  EDIT_USERS: `${USERS}/:${pathParams.NESTED_PAGE}/edit/:${pathParams.USER_ID}`,
  SETTINGS: `${SETTINGS}/:${pathParams.NESTED_PAGE}`,
  PROCESS_ORDER,
  SCAN_ORDER: `${PROCESS_ORDER}/scan-order`,
  PACKING_MODE: `${PROCESS_ORDER}/:${pathParams.ORDER_ID}/packing-mode`,
  FREIGHT_PACKING_MODE: `${PROCESS_ORDER}/:${pathParams.ORDER_ID}/freight-packing-mode`,
  MANAGE_PALLETS,
  MANAGE_PALLETS_PAGES: `${MANAGE_PALLETS}/:${pathParams.NESTED_PAGE}`,
  UNLOAD_APPOINTMENT,
  ASSIGN_APPOINTMENT_RECEIPTS: 'assign-appointment-receipts',
  COMPLETE_APPOINTMENT_TOGGLE: 'complete-appointment/select',
  VERIFY_APPOINTMENT,
  TERMINALS: 'terminals',
  SYSTEM_SERVICES: 'system-services',
  FULFILLED_SERVICES,
  FULFILLED_SERVICE_PAGES: `${FULFILLED_SERVICES}/details/:${pathParams.FULFILLED_SERVICE_ID}/:${pathParams.NESTED_PAGE}`,
  EDIT_FULFILLED_SERVICE: `${FULFILLED_SERVICES}/edit/:${pathParams.FULFILLED_SERVICE_ID}`,
  ADD_FULFILLED_SERVICE: `${FULFILLED_SERVICES}/add`,
  WAREHOUSES,
  WAREHOUSE_PAGES: `${WAREHOUSES}/details/:${pathParams.WAREHOUSE_ID}/:${pathParams.NESTED_PAGE}/:${pathParams.SUB_NESTED_PAGE}?`,
  RECEIPTS,
  RECEIPT_PAGES: `${RECEIPTS}/details/:${pathParams.RECEIPT_ID}/:${pathParams.NESTED_PAGE}`,
  ADD_RECEIPT: `${RECEIPTS}/add`,
  RECEIPT_PURCHASE_LABELS: `${RECEIPTS}/shipment/:${pathParams.RECEIPT_ID}/purchase-labels`,
  RECEIPT_TRUCKING_JOB: `${RECEIPTS}/shipment/:${pathParams.RECEIPT_ID}/trucking-jobs`,
  RECEIPT_DRAYAGE_JOB: `${RECEIPTS}/shipment/:${pathParams.RECEIPT_ID}/${DRAYAGE_JOBS}`,
  PREP_RECEIPTS: `${RECEIPTS}/prep`,
  VALIDATE_RECEIPTS: `${RECEIPTS}/validate`,
  VALIDATE_RECEIPT: `${RECEIPTS}/validate/:${pathParams.RECEIPT_ID}`,
  STOCK: `${STOCK}/:${pathParams.VIEW_TYPE}`,
  STOCK_ITEM_HISTORY: `${STOCK}/:${pathParams.VIEW_TYPE}/:${pathParams.ITEM_ID}/history`,
  LOCATIONS,
  TRACK_TIME: 'track-time',
  DRIVER_CHECK_IN: 'driver-check-ins',
  LINK_DRIVER_CHECK_IN_APPOINTMENT: `driver-check-ins/link-appointment/:${pathParams.APPOINTMENT_ID}/warehouse/:${pathParams.WAREHOUSE_ID}`,
  APPOINTMENTS,
  LINK_APPOINTMENTS_CHECK_IN: `${APPOINTMENTS}/link-check-in/:${pathParams.CHECK_IN_ID}/warehouse/:${pathParams.WAREHOUSE_ID}/company/:${pathParams.COMPANY_ID}`,
  ADD_APPOINTMENT: `${APPOINTMENTS}/add`,
  APPOINTMENT_PAGES: `${WAREHOUSES}/:${pathParams.WAREHOUSE_ID}/${APPOINTMENTS}/details/:${pathParams.APPOINTMENT_ID}/:${pathParams.NESTED_PAGE}`,
  ADD_ORDER_APPOINTMENT: `${WAREHOUSES}/:${pathParams.WAREHOUSE_ID}/${APPOINTMENTS}/services/:${pathParams.APPOINTMENT_ID}/customer/:${pathParams.CUSTOMER_ID}/orders`,
  ADD_TRUCKING_JOB_APPOINTMENT: `${WAREHOUSES}/:${pathParams.WAREHOUSE_ID}/${APPOINTMENTS}/services/:${pathParams.APPOINTMENT_ID}/customer/:${pathParams.CUSTOMER_ID}/trucking-jobs/:${pathParams.TRUCKING_JOB_TAB}`,
  ADD_DRAYAGE_JOB_APPOINTMENT: `${WAREHOUSES}/:${pathParams.WAREHOUSE_ID}/${APPOINTMENTS}/services/:${pathParams.APPOINTMENT_ID}/customer/:${pathParams.CUSTOMER_ID}/drayage-jobs/:${pathParams.DRAYAGE_JOB_TAB}`,
  ADD_RECEIPT_APPOINTMENT: `${WAREHOUSES}/:${pathParams.WAREHOUSE_ID}/${APPOINTMENTS}/services/:${pathParams.APPOINTMENT_ID}/customer/:${pathParams.CUSTOMER_ID}/receipts`,
  APPOINTMENT_CHECK_IN,
  APPOINTMENT_CHECK_IN_FORM: `${APPOINTMENT_CHECK_IN}/form`,
  APPOINTMENT_CHECK_IN_FORM_INTERNAL: `${APPOINTMENT_CHECK_IN}/internal/form`,
  INVENTORY_MOBILE_TOGGLE: 'inventory-mobile-toggle',
  MOVE_INVENTORY: 'inventory/move',
  NOT_FOUND: 'not-found',
};

/**
 * Generates a urls from path params provided. If the params are validated that
 * they will match the route then use `generateValidatedParamsUrl` instead as it's more efficient
 * @param {string} _path
 * @param {object} [_params]
 * @param {object} [_query]
 * @returns
 */
export const generateUrl = (_path, _params = {}, _query = {}) => {
  let path = _path;
  if (_params && Object.keys(_params).length) {
    const keys = Object.keys(_params);
    keys.forEach((k) => {
      if (pathParams[k]) {
        path = path.replace(`:${pathParams[k]}`, _params[k]);
      }
    });
  }

  let url = `/${path}`;

  const query = queryString.stringify(_query, { skipNull: true });
  if (query) url = `${url}?${query}`;

  return url;
};

/**
 * If the route required params then it will throw an error if it is missing.
 * If it can be missing use `generateUrl` instead.
 * @param {string} path
 * @param {object} [params]
 * @param {object} [query]
 * @returns
 */
export function generateValidatedParamsUrl(path, params, query) {
  let route = `/${generatePath(path, params)}`;
  if (query) {
    route = `${route}?${queryString.stringify(query)}`;
  }
  return route;
}
