import type { RootState } from '../types';
import {
  AppointmentFulfillmentType,
  AppointmentLoadType,
  AppointmentScheduleType,
  AppointmentServiceType,
  AppointmentStatusType,
  AppointmentUnloadType,
  ContactType,
  HazardDangerDegreeType,
  ItemUnitOfMeasureType,
  JobTitleType,
  QbECheckStatusType,
  QbChargeStatusType,
  PackingMaterialMadeFromType,
  PackingMaterialType,
  PickAllocationMethodType,
  ReceiptCarrierArrangedByType,
  ReceiptDocumentType,
  ReceiptFulfillmentType,
  ReceiptPackageType,
  ReceiptStatusType,
  SelectOption,
  ShipmentLocationType,
  ShippingCategoryType,
  SystemServiceType,
  WarehouseAppointmentTierType,
  WarehouseAssetType,
  WarehouseLocationAccessibilityType,
  WarehouseLocationType,
  WarehouseStagingAreaType,
  WarehouseStorageCategoryType,
  WarehouseVehicleType,
  WarehouseWorkstationType,
  MoveableUnitType,
  MoveableUnitUsageTypes,
  EmployeeActivityType,
  UserType,
  ReceiptIssueType,
  FeeType,
  MoveableUnitStatusType,
  DeliveryConfirmationType,
  ShipEngineInsuranceProviders,
  CustomsContentsType,
  CustomsNonDeliveryType,
  TaxEntityType,
  TaxIdentifierType,
} from '../../types';
import { InventoryItemTimelineActionType } from '../../types/inventoryTypes';

export const systemSelectors = {
  machineName: (state: RootState) => state.systemPersist.machineName,
  signalRDevicesReconnecting: (state: RootState) =>
    state.system.signalRDevicesReconnecting,
  signalRDevicesDisconnected: (state: RootState) =>
    state.system.signalRDevicesDisconnected,
  signalRDevicesSubscribedToScale: (state: RootState) =>
    state.system.signalRDevicesSubscribedToScale,
  signalRMessagesConnected: (state: RootState) =>
    state.system.signalRMessagesConnected,
  signalRDevicesConnected: (state: RootState) =>
    state.system.signalRDevicesConnected,
  signalRAlertsConnected: (state: RootState) =>
    state.system.signalRAlertsConnected,
  scaleWeight: (state: RootState) => state.system.weight || {},
  orderStatus: (state: RootState) =>
    state.systemPersist.systemSettings.orderStatus || [],
  fulfillmentTypes: (state: RootState) =>
    state.systemPersist.systemSettings.fulfillmentTypes || [],
  titleTypes: (state: RootState) =>
    state.systemPersist.systemSettings.titleTypes || [],
  states: (state: RootState) => state.systemPersist.systemSettings.states || [],
  countries: (state: RootState) =>
    (state.systemPersist.systemSettings.countries || []) as Array<
      SelectOption<string | number>
    >,
  statesObj: (state: RootState) =>
    state.systemPersist.systemSettings.statesObj || {},
  countriesObj: (state: RootState) =>
    state.systemPersist.systemSettings.countriesObj || {},
  countryUsId: (state: RootState) => state.systemPersist.countryUsId as number,
  packingMaterialTypes: (state: RootState) =>
    (state.systemPersist.systemSettings.packingMaterialTypes || []) as Array<
      SelectOption<PackingMaterialType>
    >,
  materialMadeFromTypes: (state: RootState) =>
    (state.systemPersist.systemSettings.materialMadeFromTypes || []) as Array<
      SelectOption<PackingMaterialMadeFromType>
    >,
  packingModeTypes: (state: RootState) =>
    state.systemPersist.systemSettings.packingModeTypes || [],
  materialCategoryTypes: (state: RootState) =>
    state.systemPersist.systemSettings.materialCategoryTypes || [],
  hazardClassTypes: (state: RootState) =>
    state.systemPersist.systemSettings.hazardClassTypes || [],
  unitOfMeasureTypes: (state: RootState) =>
    (state.systemPersist.systemSettings.unitOfMeasureTypes || []) as Array<
      SelectOption<ItemUnitOfMeasureType>
    >,
  materialAccountOwnerTypes: (state: RootState) =>
    state.systemPersist.systemSettings.materialAccountOwnerTypes || [],
  carrierTypes: (state: RootState) =>
    state.systemPersist.systemSettings.carrierTypes || [],
  carrierCodesTypes: (state: RootState) =>
    state.systemPersist.systemSettings.carrierCodesTypes || [],
  carrierServiceCategoryTypes: (state: RootState) =>
    state.systemPersist.systemSettings.carrierServiceCategoryTypes || [],
  freightContainerPackingTypes: (state: RootState) =>
    state.systemPersist.systemSettings.freightContainerPackingTypes || [],
  shippingCategoryTypes: (state: RootState) =>
    (state.systemPersist.systemSettings.shippingCategoryTypes || []) as Array<
      SelectOption<ShippingCategoryType>
    >,
  shipmentLocationTypes: (state: RootState) =>
    (state.systemPersist.systemSettings.shipmentLocationTypes || []) as Array<
      SelectOption<ShipmentLocationType>
    >,
  deliveryConfirmationTypes: (state: RootState) =>
    (state.systemPersist.systemSettings.deliveryConfirmationTypes ||
      []) as Array<SelectOption<DeliveryConfirmationType>>,
  orderShipmentTypes: (state: RootState) =>
    state.systemPersist.systemSettings.orderShipmentTypes || [],
  shippingFeeTypes: (state: RootState) =>
    state.systemPersist.systemSettings.shippingFeeTypes || [],
  trackingStatusTypes: (state: RootState) =>
    state.systemPersist.systemSettings.trackingStatusTypes || [],
  customerFeeTypes: (state: RootState) =>
    (state.systemPersist.systemSettings.customerFeeTypes || []) as Array<
      SelectOption<FeeType>
    >,
  specialFeeTypes: (state: RootState) =>
    (state.systemPersist.systemSettings.specialFeeTypes || []) as Array<
      SelectOption<FeeType>
    >,
  documentFeeTypes: (state: RootState) =>
    state.systemPersist.systemSettings.documentFeeTypes || [],
  receiptDocumentTypes: (state: RootState) =>
    (state.systemPersist.systemSettings.receiptDocumentTypes || []) as Array<
      SelectOption<ReceiptDocumentType>
    >,
  feeCategoryTypes: (state: RootState) =>
    state.systemPersist.systemSettings.feeCategoryTypes || [],
  feeTypes: (state: RootState) =>
    (state.systemPersist.systemSettings.feeTypes || []) as Array<
      SelectOption<FeeType>
    >,
  profitAndLossFeeTypeFilters: (state: RootState) =>
    state.systemPersist.systemSettings.profitAndLossFeeTypeFilters || [],
  palletDimensionCategoryTypes: (state: RootState) =>
    state.systemPersist.systemSettings.palletDimensionCategoryTypes || [],
  boxDimensionCategoryTypes: (state: RootState) =>
    state.systemPersist.systemSettings.boxDimensionCategoryTypes || [],
  soldByTypes: (state: RootState) =>
    state.systemPersist.systemSettings.soldByTypes || [],
  jobTitleTypes: (state: RootState) =>
    (state.systemPersist.systemSettings.jobTitleTypes || []) as Array<
      SelectOption<JobTitleType>
    >,
  contactTypes: (state: RootState) =>
    (state.systemPersist.systemSettings.contactTypes || []) as Array<
      SelectOption<ContactType>
    >,
  roleTypes: (state: RootState) =>
    state.systemPersist.systemSettings.roles || [],
  userTypes: (state: RootState) =>
    (state.systemPersist.systemSettings.userTypes || []) as Array<
      SelectOption<UserType>
    >,
  paymentMethodTypes: (state: RootState) =>
    state.systemPersist.systemSettings.paymentMethodTypes || [],
  paymentTermTypes: (state: RootState) =>
    state.systemPersist.systemSettings.paymentTermTypes || [],
  invoiceFrequencyTypes: (state: RootState) =>
    state.systemPersist.systemSettings.invoiceFrequencyTypes || [],
  shippingSelectionPreferenceTypes: (state: RootState) =>
    state.systemPersist.systemSettings.shippingSelectionPreferenceTypes || [],
  shipEngineInsuranceProviderTypes: (state: RootState) =>
    (state.systemPersist.systemSettings.shipEngineInsuranceProviderTypes ||
      []) as Array<SelectOption<ShipEngineInsuranceProviders>>,
  carrierAccountOwnerTypes: (state: RootState) =>
    state.systemPersist.systemSettings.carrierAccountOwnerTypes || [],
  shipApiTypes: (state: RootState) =>
    state.systemPersist.systemSettings.shipApiTypes || [],
  customerShipmentLabelTypes: (state: RootState) =>
    state.systemPersist.systemSettings.customerShipmentLabelTypes || [],
  batchTemplates: (state: RootState) => state.system.batchTemplates || [],
  customsContentsType: (state: RootState) =>
    (state.systemPersist.internationalShippingTypes.customsContentsType ||
      []) as Array<SelectOption<CustomsContentsType>>,
  customsNonDeliveryType: (state: RootState) =>
    (state.systemPersist.internationalShippingTypes.customsNonDeliveryType ||
      []) as Array<SelectOption<CustomsNonDeliveryType>>,
  taxEntityType: (state: RootState) =>
    (state.systemPersist.internationalShippingTypes.taxEntityType ||
      []) as Array<SelectOption<TaxEntityType>>,
  taxIdentifierType: (state: RootState) =>
    (state.systemPersist.internationalShippingTypes.taxIdentifierType ||
      []) as Array<SelectOption<TaxIdentifierType>>,
  reshipReturnReasonTypes: (state: RootState) =>
    state.systemPersist.systemSettings.reshipReturnReasonTypes || [],
  shipEngineReturnChargeEventTypes: (state: RootState) =>
    state.systemPersist.systemSettings.shipEngineReturnChargeEventTypes || [],
  openShipmentJobStatus: (state: RootState) =>
    state.systemPersist.systemSettings.openShipmentJobStatusTypes || [],
  scheduledShipmentJobStatus: (state: RootState) =>
    state.systemPersist.systemSettings.scheduledShipmentJobStatusTypes || [],
  reportTypes: (state: RootState) =>
    state.systemPersist.systemSettings.reportTypes || [],
  openDrayageLoadStatusTypes: (state: RootState) =>
    state.systemPersist.systemSettings.openDrayageLoadStatusTypes || [],
  completedDrayageLoadStatusTypes: (state: RootState) =>
    state.systemPersist.systemSettings.completedDrayageLoadStatusTypes || [],
  drayageLoadStatusTypes: (state: RootState) =>
    state.systemPersist.systemSettings.drayageLoadStatusTypes || [],
  cardTypes: (state: RootState) =>
    state.systemPersist.systemSettings.cardTypes || [],
  creditCardProcessingFeeTypes: (state: RootState) =>
    state.systemPersist.systemSettings.creditCardProcessingFeeTypes || [],
  invoiceStatusTypes: (state: RootState) =>
    state.systemPersist.systemSettings.invoiceStatusTypes || [],
  invoiceTypes: (state: RootState) =>
    state.systemPersist.systemSettings.invoiceTypes || [],
  billStatusTypes: (state: RootState) =>
    state.systemPersist.systemSettings.billStatusTypes || [],
  reconciliationTypes: (state: RootState) =>
    state.systemPersist.systemSettings.reconciliationTypes || [],
  basicQbVendors: (state: RootState) => state.system.basicQbVendors || [],
  basicQbTerms: (state: RootState) => state.system.basicQbTerms || [],
  systemServiceTypes: (state: RootState) =>
    (state.systemPersist.systemSettings.systemServiceTypes || []) as Array<
      SelectOption<SystemServiceType>
    >,
  employeeActivityTypes: (state: RootState) =>
    (state.systemPersist.systemSettings.employeeActivityTypes || []) as Array<
      SelectOption<EmployeeActivityType>
    >,
  warehouseClosedDayTypes: (state: RootState) =>
    state.systemPersist.systemSettings.warehouseClosedDayTypes || [],
  receiptPackageTypes: (state: RootState) =>
    (state.systemPersist.systemSettings.receiptPackageTypes || []) as Array<
      SelectOption<ReceiptPackageType>
    >,
  receiptStatusTypes: (state: RootState) =>
    (state.systemPersist.systemSettings.receiptStatusTypes || []) as Array<
      SelectOption<ReceiptStatusType>
    >,
  receiptFulfillmentTypes: (state: RootState) =>
    (state.systemPersist.systemSettings.receiptFulfillmentTypes || []) as Array<
      SelectOption<ReceiptFulfillmentType>
    >,
  receiptCarrierArrangedByTypes: (state: RootState) =>
    (state.systemPersist.systemSettings.receiptCarrierArrangedByTypes ||
      []) as Array<SelectOption<ReceiptCarrierArrangedByType>>,
  pickAllocationMethodTypes: (state: RootState) =>
    (state.systemPersist.systemSettings.pickAllocationMethodTypes ||
      []) as Array<SelectOption<PickAllocationMethodType>>,
  appointmentStatusTypes: (state: RootState) =>
    (state.systemPersist.systemSettings.appointmentStatusTypes || []) as Array<
      SelectOption<AppointmentStatusType>
    >,
  appointmentScheduleTypes: (state: RootState) =>
    (state.systemPersist.systemSettings.appointmentScheduleTypes ||
      []) as Array<SelectOption<AppointmentScheduleType>>,
  appointmentServiceTypes: (state: RootState) =>
    (state.systemPersist.systemSettings.appointmentServiceTypes || []) as Array<
      SelectOption<AppointmentServiceType>
    >,
  appointmentFulfillmentTypes: (state: RootState) =>
    (state.systemPersist.systemSettings.appointmentFulfillmentTypes ||
      []) as Array<SelectOption<AppointmentFulfillmentType>>,
  appointmentLoadTypes: (state: RootState) =>
    (state.systemPersist.systemSettings.appointmentLoadTypes || []) as Array<
      SelectOption<AppointmentLoadType>
    >,
  appointmentUnloadTypes: (state: RootState) =>
    (state.systemPersist.systemSettings.appointmentUnloadTypes || []) as Array<
      SelectOption<AppointmentUnloadType>
    >,
  warehouseAppointmentTierTypes: (state: RootState) =>
    (state.systemPersist.systemSettings.warehouseAppointmentTierTypes ||
      []) as Array<SelectOption<WarehouseAppointmentTierType>>,
  warehouseLocationTypes: (state: RootState) =>
    (state.systemPersist.systemSettings.warehouseLocationTypes || []) as Array<
      SelectOption<WarehouseLocationType>
    >,
  warehouseStorageCategoryTypes: (state: RootState) =>
    (state.systemPersist.systemSettings.warehouseStorageCategoryTypes ||
      []) as Array<SelectOption<WarehouseStorageCategoryType>>,
  warehouseStagingAreaTypes: (state: RootState) =>
    (state.systemPersist.systemSettings.warehouseStagingAreaTypes ||
      []) as Array<SelectOption<WarehouseStagingAreaType>>,
  warehouseLocationAccessibilityTypes: (state: RootState) =>
    (state.systemPersist.systemSettings.warehouseLocationAccessibilityTypes ||
      []) as Array<SelectOption<WarehouseLocationAccessibilityType>>,
  warehouseAssetTypes: (state: RootState) =>
    (state.systemPersist.systemSettings.warehouseAssetTypes || []) as Array<
      SelectOption<WarehouseAssetType>
    >,
  warehouseWorkstationTypes: (state: RootState) =>
    (state.systemPersist.systemSettings.warehouseWorkstationTypes ||
      []) as Array<SelectOption<WarehouseWorkstationType>>,
  warehouseVehicleTypes: (state: RootState) =>
    (state.systemPersist.systemSettings.warehouseVehicleTypes || []) as Array<
      SelectOption<WarehouseVehicleType>
    >,
  qbChargeStatusTypes: (state: RootState) =>
    (state.systemPersist.systemSettings.qbChargeStatusTypes || []) as Array<
      SelectOption<QbChargeStatusType>
    >,
  qbECheckStatusTypes: (state: RootState) =>
    (state.systemPersist.systemSettings.qbECheckStatusTypes || []) as Array<
      SelectOption<QbECheckStatusType>
    >,
  hazardDangerDegreeTypes: (state: RootState) =>
    (state.systemPersist.systemSettings.hazardDangerDegreeTypes || []) as Array<
      SelectOption<HazardDangerDegreeType>
    >,
  moveableUnitTypes: (state: RootState) =>
    (state.systemPersist.systemSettings.moveableUnitTypes || []) as Array<
      SelectOption<MoveableUnitType>
    >,
  moveableUnitStatusTypes: (state: RootState) =>
    (state.systemPersist.systemSettings.moveableUnitStatusTypes || []) as Array<
      SelectOption<MoveableUnitStatusType>
    >,
  moveableUnitUsageTypes: (state: RootState) =>
    state.systemPersist.systemSettings.moveableUnitUsageTypes as Array<
      SelectOption<MoveableUnitUsageTypes>
    >,
  receiptIssueTypes: (state: RootState) =>
    state.systemPersist.systemSettings.receiptIssueTypes as Array<
      SelectOption<ReceiptIssueType>
    >,
  inventoryItemTimelineActionTypes: (state: RootState) =>
    state.systemPersist.systemSettings
      .inventoryItemTimelineActionTypes as Array<
      SelectOption<InventoryItemTimelineActionType>
    >,
};

export const resourceQueryParams = (resource: string) => (state: RootState) =>
  state.lists.resources[resource]?.params.queryParams;
