import { memo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { registerLists, Resource } from './state';

// Example  ['users', 'users:id']
export const lists = [
  'carriers-accounts',
  'orders',
  'batch-orders', // filtered order list to batch process
  'batches',
  'completed-batch-orders',
  'completed-batch-errors',
  'order-items',
  'order-notes',
  'order-documents',
  'order-photos',
  'order-service-fees',
  'order-fees',
  'vendors',
  'material_products',
  'customers',
  'customer-orders',
  'customer-items',
  'customer-carriers-sp',
  'customer-carriers-freight',
  'customer-carriers-drayage',
  'customer-reseller-bills',
  'customer-documents',
  'customer-invoices',
  'customer-payments',
  'non-invoiced-services',
  'invoice-fees',
  'invoice-summary',
  'invoice-notes',
  'users_customers',
  'users_internal',
  'batch-templates',
  'system-cc-fees',
  'system-vendors',
  'fulfilled-services',
  'fulfilled-service-documents',
  'fulfilled-service-photos',
  'shipments',
  'trucking-jobs',
  'trucking-jobs-fees',
  'trucking-jobs-documents',
  'trucking-jobs-photos',
  'terminals',
  'drayage-jobs',
  'drayage-jobs-documents',
  'drayage-jobs-photos',
  'drayage-jobs-fees',
  'inventory-stock-quick-view',
  'inventory-stock-details',
  'invoices',
  'accounts-payable',
  'accounts-payable-bill-shipments',
  'bills',
  'bill-shipments',
  'material-details-report',
  'material-report',
  'material-customers-report',
  'material-per-customer-report',
  'material-per-category-report',
  'material-vendors-report',
  'customers-services-dashboard',
  'services-fees-types-report',
  'services-fees-types-warehouse-report',
  'services-fees-report',
  'services-fees-category-report',
  'services-fees-category-type-report',
  'services-fulfilled-services-report',
  'stock-item-history',
  'warehouses',
  'warehouse-docks',
  'warehouse-locations',
  'warehouse-assets',
  'warehouse-workstations',
  'employee-activity-report',
  'employee-activity-type-report',
  'order-fulfillment-employee-report',
  'order-fulfillment-services-report',
  'freight-dispatching-services-report',
  'special-projects-services-report',
  'label-purchasing-services-report',
  'reseller-services-report',
  'drayage-job-services-report',
  'trucking-jobs-services-report',
  'fulfilled-services-report',
  'order-physical-items-report',
  'order-late-orders-report',
  'order-same-day-report',
  'order-items-report',
  'drayage-status-changes-report',
  'purchased-labels',
  'purchase-labels-fees',
  'drayage-shipment-fees',
  'receipts',
  'receipt-assigned-users',
  'receipt-documents',
  'receipt-item-received',
  'receipt-item-issues',
  'receipt-item-mu',
  'receipt-notes',
  'receipt-photos',
  'driver-check-in',
  'appointments',
  'appointment-photos',
  'appointment-documents',
  'appointment-fees',
  'orderfulfillment-employee-activities',
  'drayagejob-employee-activities',
  'truckingjob-employee-activities',
  'receipt-employee-activities',
  'receipt-fees',
  'appointment-employee-activities',
];

function RegisterLists() {
  const dispatch = useDispatch();

  useEffect(() => {
    const listResources = lists.reduce((acc, cur) => {
      acc[cur] = getDefaultListParams(cur);
      return acc;
    }, {});
    dispatch(registerLists(listResources));
  }, [dispatch]);

  return null;
}
export default memo(RegisterLists);

export const getDefaultListParams = (resource: string): Resource => ({
  name: resource,
  data: {},
  ids: [],
  params: {
    page: 0,
    numberOfRows: -1, // the total amount of rows in the DB
    rowsPerPage: undefined,
    order: undefined,
    orderBy: undefined,
    filter: {},
    queryParams: {},
    selectedIds: [],
    selectedRows: {},
    total: 0,
    hasList: false,
  },
});
